
<template>
  <div class="main-content">


    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <b-tabs
        active-nav-item-class="nav nav-tabs"
        content-class="mt-3"
    >
      <b-tab title="Users" active>
        <div class="row">
          <div class="col-md-3">
            <b-input-group append="Find" class="mb-2 mr-sm-2 mb-sm-0">
              <b-input id="inline-form-input-username" placeholder="Search user"></b-input>
            </b-input-group>
          </div>
          <div class="col-md-9">
            <b-button class="btn btn-danger float-right" v-b-modal.add-user>Add new</b-button>
          </div>
        </div>
        <br/>
        <b-table striped hover :items="users" :fields="columns">
          <template v-slot:cell(role)="data">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <span>{{ data.value }}</span>

          </template>

          <template v-slot:cell(username)="data">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <span>{{ data.value }}</span>

          </template>

          <template v-slot:cell(phoneNumber)="data">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <span>{{ data.value }}</span>

          </template>

          <template v-slot:cell(status)="data">
            <div v-if="data.value != null" >
							<span  class="badge badge-success"  v-if="data.value == 'ACTIVE'" >
								Active
							</span>
              <span  class="badge badge-warning"  v-else-if="data.value == 'INACTIVE'" >
								Inactive
							</span>
              <span class="badge badge-danger"  v-else-if="data.value == 'SUSPENDED'" >
								Suspended
							</span>
            </div>
          </template>
          <template v-slot:cell(options)="data">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <b-dropdown text="Action">
              <b-dropdown-item href="#" @click="openEditUserModal(data.item)">Edit</b-dropdown-item>
              <b-dropdown-item href="#" v-if="data.item.role != 'Administrator'" @click="openResetPassword(data.item)">Reset Password</b-dropdown-item>
              <b-dropdown-item href="#" @click="openDeleteUser(data.item)">Delete</b-dropdown-item>
            </b-dropdown>

          </template>

        </b-table>
      </b-tab>
      <b-tab title="API Clients">
        <b-table striped hover :items="apiClients" :fields="apiClientsColumns">
          <template v-slot:cell(outlet)="data">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <span v-if="data.item.outlet">{{ data.item.outlet.name }}</span>

          </template>

          <template v-slot:cell(options)="data">
            <!-- `data.value` is the value after formatted by the Formatter -->

          </template>

        </b-table>
      </b-tab>
    </b-tabs>



    <b-modal id="add-user" title="Add User" @cancel="true" @ok="validateBeforeSubmitNewUser"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="user.name" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Phone number:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="user.phoneNumber" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Email:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="user.username" />
                </div>
              </div>

              <div class="form-group ">
                <label class="control-label ">Role:</label> <br/>
                <div >
                  <select class="form-control" v-model="user.role">
                    <option :value="role.role" v-for="role in roles"> {{ role.role }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
    <b-modal id="edit-user" title="Edit User" @cancel="true" @ok="validateBeforeSubmitEditUser"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="user.name" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Phone number:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="user.phoneNumber" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Email:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="user.username" />
                </div>
              </div>

              <div class="form-group ">
                <label class="control-label ">Role:</label> <br/>
                <div >
                  <select class="form-control" v-model="user.role">
                    <option :value="role.role" v-for="role in roles"> {{ role.role }}</option>
                  </select>
                </div>
              </div>

              <div class="form-group" v-if="user.role == 'Cashier'">
                <label class="control-label ">Tag:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="user.tag" />
                </div>
              </div>

              <div class="form-group ">
                <label class="control-label ">Status:</label> <br/>
                <div >
                  <label for="active">
                    <input type="radio" name="status" id="active" v-model="user.status" value="ACTIVE" /> Active
                  </label>
                  <br/>
                  <label for="suspended">
                    <input type="radio" name="status" id="suspended" v-model="user.status" value="SUSPENDED" /> Suspended
                  </label>
                  <br/>
                  <label for="inactive">
                    <input type="radio" name="status" id="inactive" v-model="user.status" value="INACTIVE" /> Inactive
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>

  </div>
</template>
<script>

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'

  import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      users:[],
      roles:[],
      columns:["name","username","role","status","options"],
      apiClientsColumns:["name","apiKey","outlet"],
      nps_entities:[],
      ranks:[],
      user:{
        name:"",
        username:"",
        phoneNumber:"",
        role:"",
        tag:"N/A"
      },
      isLoading: false,
      fullPage: true,
      filteredRanks:[],
      apiClients:[]
    };
  },
  computed: {

  },
  components: {
    Loading
  },
  mounted (){
    this.getUsersData();
    this.getRolesData();
    this.getApiClientsData();
  },

  methods: {
    ...mapActions(["addUser","editUser","deleteUser","getUsers","getRoles","resetPassword","getApiClients"]),

    getRolesData(){
      let self = this;
      this.getRoles()
        .then((roles) => {
          self.roles = roles;
        })
        .catch(error => {

        })
    },

    openEditUserModal(user){

      this.user = user;

      this.$bvModal.show('edit-user');

    },

    openResetPassword(user){

      this.user = user;

      let self = this;
      this.$bvModal.msgBoxConfirm('Reset password for '+this.user.name +'?')
              .then(value => {

                if(value){
                  self.confirmResetPassword();
                }

              })
              .catch(err => {
                // An error occurred
              })
    },

    openDeleteUser(user){

      this.user = user;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.user.name +' from system')
        .then(value => {

          if(value){
            self.confirmDeleteUser();
          }

        })
        .catch(err => {
          // An error occurred
        })
    },
    confirmDeleteUser(){
      this.isLoading = true;

      let self = this;

      this.deleteUser({user_id: this.user.id}).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("User deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getUsersData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        })

    },

    confirmResetPassword(){
      this.isLoading = true;

      let self = this;

      this.resetPassword({userId: this.user.id}).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("User password sent to email / phone number.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getUsersData();

      })
              .catch(error => {

                self.isLoading = false;

                if( typeof error.response.status != "undefined"){
                  if(error.response.status == 401){
                    location.replace("/login");
                  }

                }
                else{

                  self.$bvToast.toast(error.message, {
                    title: 'Error',
                    variant: 'danger',
                    autoHideDelay: 5000,
                    appendToast: false
                  });


                }
              })

    },

    getUsersData(){
      let self = this;
      this.getUsers()
        .then(function (users){

          self.users = users.filter(function (user) {
              return user.username != "yaya";
          });
        })
        .catch(function (error) {

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    getApiClientsData(){
      let self = this;
      this.getApiClients()
          .then(function (apiClients){
            self.apiClients = apiClients;
          })
          .catch(function (error) {

            if( typeof error.response.status != "undefined"){
              if(error.response.status == 401){
                location.replace("/login");
              }

            }
          })
    },

    validateBeforeSubmitEditUser(){
      this.isLoading = true;

      let self = this;

      this.editUser(this.user).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("User saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.user.name = "";
        self.user.username = "";
        self.user.phoneNumber = "";
        self.user.role = "";
        self.user.tag = "";

        self.getUsersData();

      })
        .catch(error => {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });

          /*if( typeof error.response.status != "undefined"){
             if(error.response.status == 401){
               location.replace("/app/sessions/signIn");
             }

          }
          else{




          }*/
        })
    },
    validateBeforeSubmitNewUser(){
      this.isLoading = true;

      let self = this;

      this.addUser(this.user).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("User added.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.user.name = "";
        self.user.username = "";
        self.user.phoneNumber = "";
        self.user.role = "";
        self.user.tag = "";


        self.getUsersData();

      })
        .catch(error => {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });

          /*if( typeof error.response.status != "undefined"){
             if(error.response.status == 401){
               location.replace("/app/sessions/signIn");
             }

          }
          else{




          }*/
        })
    }
  }
};
</script>
